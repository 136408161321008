<template>
  <section class="section">
    <nav class="container">
      <h2 class="title">
        {{ $tf("dashboardSummary.timeSheet|Heti munkarend") }}
      </h2>
      <time-sheet-summary
        v-if="loggingRequired && !isLoading && actWeekStart && actWeekEnds"
        :from="actWeekStart"
        :to="actWeekEnds"
      >
      </time-sheet-summary>
    </nav>

    <div class="is-flex has-right-margin" v-if="!isLoading">
      <div class="container p-4 mr-0" style="flex: 1">
        <h3 class="heading mb-0">
          <loading-component>
            {{ $tf("dashboardSummary.type|Típus") }}
          </loading-component>
        </h3>
        <loading-component>
          <b-tabs
            v-model="isShowingAbsenceOrPlace"
            type="is-toggle"
            class="toggle-only"
            vertical
          >
            <b-tab-item
              :value="DASHBOARD_TABS.ABSENCES"
              :label="$tf('dashboardSummary.absences|Hiányzások')"
            />
            <b-tab-item
              :value="DASHBOARD_TABS.WORKSCHEDULES"
              :label="$tf('dashboard.workschedules|Beosztások')"
            />
          </b-tabs>
        </loading-component>
        <div v-if="teamTags.length">
          <h3 class="heading mb-2 mt-4">
            <loading-component>{{
              $tf("dashboardSummary.filterTeams|Szűrés csapatokra")
            }}</loading-component>
          </h3>
          <loading-component class="is-flex is-flex-wrap-wrap has-gap-2">
            <template #loader>
              <div-with-loading
                v-for="n in 20"
                :key="n"
                loading-height="32px"
                loading-width="100%"
              ></div-with-loading>
            </template>
            <div
              v-for="(team, index) in teamTags"
              :key="index"
              class="absence-tag"
              :style="getTeamColor(index, team.selected)"
              @click="clickOnTeamTag(index)"
            >
              <div class="is-flex is-align-items-center has-gap-1">
                <b-icon size="is-small" v-if="team.selected" icon="check" />
                <f-avatar
                  :data="getAvatarForTeam(team)"
                  size="24x24"
                  :font-size="0.8"
                />
                <span>{{ team.name }}</span>
              </div>
            </div>
          </loading-component>
        </div>

        <div>
          <template
            v-if="isShowingAbsenceOrPlace === DASHBOARD_TABS.WORKSCHEDULES"
          >
            <h3 class="heading mb-2 mt-4">
              <loading-component>{{
                $tf("dashboardSummary.filterWorkplaces|Szűrés irodákra")
              }}</loading-component>
            </h3>
            <loading-component>
              <template #loader></template>
              <div class="is-flex is-flex-wrap-wrap has-gap-2">
                <b-tag
                  v-for="(place, index) in this.workplaceTags"
                  :key="index"
                  class="absence-tag"
                  :style="style(place.color, place.selected)"
                  @click="clickOnTag(index)"
                  :icon="place.selected ? 'check' : null"
                >
                  {{ place.identifier }}
                </b-tag>
              </div>
            </loading-component>
          </template>

          <!--          <template v-else>-->
          <!--            <h3 class="heading mb-2 mt-4">Szűrés hiányzástípusra</h3>-->
          <!--            <div class="is-flex is-flex-wrap-wrap has-gap-2">-->
          <!--              <b-tag-->
          <!--                v-for="(type, index) in absenceTypeTags"-->
          <!--                :key="index"-->
          <!--                class="absence-tag"-->
          <!--                :style="style(type.color, type.selected)"-->
          <!--                @click="clickOnAbsenceTypeTag(index)"-->
          <!--                :icon="type.selected ? 'check' : null"-->
          <!--              >-->
          <!--                {{ type.name }}-->
          <!--              </b-tag>-->
          <!--            </div>-->
          <!--          </template>-->
        </div>

        <loading-component>
          <b-button
            class="mt-5"
            type="is-light"
            :label="$tf('dashboardSummary.resetFilters|Szűrők törlése')"
            @click="resetFilters"
          />
        </loading-component>
      </div>
      <div style="flex: 10">
        <WeeklyCalendar
          v-if="absences && isShowingAbsenceOrPlace === DASHBOARD_TABS.ABSENCES"
          :absences="absences"
          :absence-types-to-show="
            absenceTypeTags.some((tag) => tag.selected)
              ? absenceTypeTags.filter((tag) => tag.selected)
              : absenceTypeTags
          "
          :employees-to-show="employeesOfSelectedTeams"
          :teams-to-show="selectedTeams.length === 0 ? teams : selectedTeams"
          :from="absenceDates.from"
          :to="absenceDates.to"
          @update="updateDates"
        />
        <WeeklyCalendar
          v-else-if="
            workschedules &&
            isShowingAbsenceOrPlace === DASHBOARD_TABS.WORKSCHEDULES
          "
          :workschedules="workschedules"
          :workplaces-to-show="
            selectedWorkplaces.length === 0 ? workplaceTags : selectedWorkplaces
          "
          :employees-to-show="employeesOfSelectedTeams"
          :teams-to-show="selectedTeams.length === 0 ? teams : selectedTeams"
          :from="absenceDates.from"
          :to="absenceDates.to"
          @update="updateDates"
        />
      </div>
    </div>
  </section>
</template>

<script>
import WeeklyCalendar from "../../components/dashboard/WeeklyCalendar";
import TimeSheetSummary from "@/components/timesheet/TimeSheetSummary";
import { mapGetters } from "vuex";
import {
  deepCopy,
  formatDate,
  getContrastedColor,
  hslToHex,
} from "@/utils/util";
import { TEAM_LEAD_ROLE } from "@/utils/const";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import DivWithLoading from "@/components/loading/DivWithLoading.vue";
import FAvatar from "@/components/module/icon/FAvatar.vue";

const DASHBOARD_TABS = {
  ABSENCES: 0,
  WORKSCHEDULES: 1,
};

export default {
  name: "PortalDashboard",
  components: {
    FAvatar,
    DivWithLoading,
    WeeklyCalendar,
    TimeSheetSummary,
    LoadingComponent,
  },
  async mounted() {
    this.absenceDates = {
      from: new Date(this.actWeekStart),
      to: new Date(this.actWeekEnds).addDays(7),
    };

    await this.fetchData();
    this.workplaceTags = deepCopy(this.workplaces) ?? [];
    this.workplaceTags.forEach((tag) => (tag.selected = false));

    if (this.teams) {
      this.teamTags = deepCopy(this.teams);
      this.teamTags.forEach((tag) => (tag.selected = false));
    }
    this.absenceTypeTags = deepCopy(this.absenceTypes);
    this.absenceTypeTags.forEach((tag) => (tag.selected = false));
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      isShowingAbsenceOrPlace: DASHBOARD_TABS.ABSENCES,
      workplaceTags: [],
      teamTags: [],
      absenceTypeTags: [],
      selectedTeamsToShow: [],
      selectedTeamsToShowFiltered: [],
      DASHBOARD_TABS,
      absenceDates: {
        from: new Date(),
        to: null,
      },
    };
  },
  watch: {
    isShowingAbsenceOrPlace() {
      this.fetchCalendarData();
    },
  },
  computed: {
    actWeekStart() {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay() + 1; //+1 because week starts with monday
      return formatDate(new Date(curr.setDate(first)));
    },
    actWeekEnds() {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay() + 1; //+1 because week starts with monday
      return formatDate(new Date(curr.setDate(first + 6)));
    },
    selectedTeams() {
      return this.teamTags?.filter((tag) => tag.selected) || [];
    },
    selectedWorkplaces() {
      return this.workplaceTags?.filter((tag) => tag.selected) || [];
    },
    employeesOfSelectedTeams() {
      let colleagues = [];
      if (!this.selectedTeams?.length) return null;
      this.selectedTeams.forEach((team) =>
        team.employees
          .concat(team.leads)
          .forEach((employee) => colleagues.push(employee))
      );
      return colleagues;
    },
    ...mapGetters({
      absences: "dashboard/absences",
      workschedules: "dashboard/workschedules",
      workplaces: "work_schedule/workScheduleSites",
      teams: "census_team/teams",
      absenceTypes: "absence_request/types",
      loggingRequired: "session/loggingRequired",
      employees: "employee/currentEmployees",
    }),
  },
  methods: {
    updateDates(dates) {
      this.absenceDates = dates;
      this.fetchCalendarData();
    },
    async fetchCalendarData() {
      const params = new URLSearchParams();
      params.set("from", formatDate(this.absenceDates.from));
      params.set("to", formatDate(this.absenceDates.to));
      if (this.isShowingAbsenceOrPlace === DASHBOARD_TABS.ABSENCES) {
        this.$store.dispatch("dashboard/fetch", {
          params: {
            params,
          },
        });
      } else {
        this.$store.dispatch("dashboard/fetchWorkSchedules", {
          params: {
            params,
          },
        });
      }
    },
    async fetchData() {
      const promises = [];
      promises.push(
        this.fetchCalendarData(),
        this.$store.dispatch("work_schedule/getWorkScheduleAdminSites"),
        this.$store.dispatch("census_team/fetchTeams"),
        this.$store.dispatch("absence_request/getAdminTypes")
      );
      await Promise.all(promises);
    },
    style(color, selected) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      result = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      let selectedHex = selected ? "FF" : "BB";
      selected = selected ? "1" : "0.3";
      return (
        "color: " +
        getContrastedColor(color) +
        selectedHex +
        ";" +
        "background-color: rgba(" +
        result.r +
        ", " +
        result.g +
        ", " +
        result.b +
        ", " +
        selected +
        ");"
      );
    },
    getTeamColor(index, selected) {
      const hue = index * 137.508; // use golden angle approximation
      return this.style(hslToHex(hue, 50, 75), selected);
    },
    clickOnTag(tagId) {
      let tag = this.workplaceTags[tagId];
      tag.selected = !tag.selected;
      this.workplaceTags.tagId = tag;
    },
    clickOnTeamTag(tagId) {
      let tag = this.teamTags[tagId];
      tag.selected = !tag.selected;
      this.teamTags.tagId = tag;
    },
    clickOnAbsenceTypeTag(tagId) {
      let tag = this.absenceTypeTags[tagId];
      tag.selected = !tag.selected;
      this.absenceTypeTags.tagId = tag;
    },
    resetFilters() {
      this.teamTags.forEach((tag, tagIndex) => {
        tag.selected = false;
        this.teamTags.tagIndex = tag;
      });
      this.workplaceTags.forEach((tag, tagIndex) => {
        tag.selected = false;
        this.workplaceTags.tagIndex = tag;
      });
      this.absenceTypeTags.forEach((tag, tagIndex) => {
        tag.selected = false;
        this.absenceTypeTags.tagIndex = tag;
      });
      this.isShowingAbsenceOrPlace = DASHBOARD_TABS.ABSENCES;
    },
    getAvatarForTeam(team) {
      const avatarObject = {
        name: team.name,
      };
      if (!team.leads.length) {
        return avatarObject;
      }

      let leadId = null;

      let lead = team.leads.find(
        (lead) => lead.role === TEAM_LEAD_ROLE.ENUM.RESOURCE
      );
      if (lead) {
        leadId = lead.employeeId;
      } else {
        lead = team.leads.find(
          (lead) => lead.role === TEAM_LEAD_ROLE.ENUM.FINANCIAL
        );

        if (lead) {
          leadId = lead.employeeId;
        } else {
          return avatarObject;
        }
      }

      avatarObject.avatar = this.employees.find(
        (emp) => emp.id === leadId
      )?.avatar;
      return avatarObject;
    },
    getLeadForTeam(team) {
      if (!team.leads.length) {
        return "";
      }

      let leadId = null;

      let lead = team.leads.find(
        (lead) => lead.role === TEAM_LEAD_ROLE.ENUM.RESOURCE
      );
      if (lead) {
        leadId = lead.employeeId;
      } else {
        lead = team.leads.find(
          (lead) => lead.role === TEAM_LEAD_ROLE.ENUM.FINANCIAL
        );

        if (lead) {
          leadId = lead.employeeId;
        } else {
          return "";
        }
      }

      let employee = this.employees.filter((emp) => {
        return emp.id === leadId;
      });
      return employee[0] ? employee[0].name : "";
    },
  },
};
</script>

<style lang="scss">
.absence-dashboard-filter-button {
  margin-top: 10px;
  margin-right: 10px;
}
.absence-dashboard-reset-selection-button {
  margin-top: 10px;
}
.absence-tag {
  user-select: none;
  cursor: pointer;
  font-size: 0.75rem;
  border-radius: 4px;
  padding: 4px 6px;
}
</style>
