<template>
  <div class="scrollable-modal-card">
    <div class="container">
      <header class="modal-card-head">
        <h1 v-if="newUser" class="title">
          {{ $tf("userModal.newTitle|Új munkatárs felvétele") }}
        </h1>
        <h1 v-else class="title">
          {{
            $tf("userModal.editTitle|{name} módosítása", {
              name: userData.name,
            })
          }}
        </h1>
      </header>
      <section class="modal-card-body">
        <form>
          <h3 class="heading">{{ $tf("userForm.basics.title|Alapadatok") }}</h3>

          <div class="columns">
            <div class="column">
              <b-field
                :label="$tf('userForm.identifier|Azonosító')"
                expanded
                label-position="on-border"
              >
                <b-input :value="userId" disabled></b-input>
              </b-field>
            </div>

            <div class="column">
              <vuelidated-input
                v-model="userEdit.name"
                :label="$tf('userForm.name|Név')"
                :placeholder="$tf('userForm.name.placeholder|Munkatárs neve')"
                :ref-value="'name'"
                :validation-rule="v$.userEdit.name"
                expanded
                label-position="on-border"
              />
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <vuelidated-input
                :model-value="userEdit.username"
                :disabled="!newUser"
                :label="$tf('userForm.username|Felhasználónév')"
                :placeholder="
                  $tf('userForm.username.placeholder|Munkatárs felhasználóneve')
                "
                :validation-rule="v$.userEdit.username"
                expanded
                label-position="on-border"
                @update:modelValue="
                  (val) => {
                    if (newUser) userEdit.username = val;
                  }
                "
              />
            </div>
            <div class="column">
              <vuelidated-input
                v-model="userEdit.email"
                :label="$tf('userForm.email|E-mail cím')"
                :placeholder="
                  $tf('userForm.email.placeholder|Munkatárs e-mail címe')
                "
                :validation-rule="v$.userEdit.email"
                expanded
                label-position="on-border"
              />
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <phone-input
                ref="phoneInput"
                v-model="userEdit.phone"
                :label="$tf('userForm.phone|Telefonszám')"
                :placeholder="
                  $tf('userForm.phone.placeholder|Munkatárs telefonszáma')
                "
                class="phone-input"
                required
                @validate="validPhoneInput = $event"
                :force-error="forcePhoneInputError"
                data-field="phone"
              />
            </div>

            <div class="column">
              <div class="switch-column is-flex has-gap-5 is-align-items-start">
                <b-switch
                  v-model="userEdit.locked"
                  :rounded="false"
                  class="field"
                  type="is-danger"
                >
                  <label>{{ $tf("userForm.locked|Zárolt") }}</label>
                </b-switch>

                <b-switch
                  v-model="userEdit.hidden"
                  :rounded="false"
                  class="field"
                  type="is-danger"
                >
                  <label>{{ $tf("userForm.hidden|Rejtett") }}</label>
                </b-switch>
              </div>
            </div>
          </div>

          <div v-if="!newUser" class="columns">
            <div class="column">
              <b-field>
                <b-button type="is-info" @click="resetPassword">
                  {{ $tf("userForm.resetPassword|Jelszó helyreállítása") }}
                </b-button>
              </b-field>
            </div>

            <div class="column"></div>
          </div>

          <h3 class="heading">
            {{ $tf("userForm.companySettings.title|Céges beállítások") }}
          </h3>

          <div class="columns">
            <div class="column">
              <vuelidated-input
                :label="$tf('userForm.startDate|Belépés dátuma')"
                expanded
                label-position="on-border"
                v-model="userEdit.startDate"
                :placeholder="
                  $tf('userForm.startDate.placeholder|Belépés dátuma')
                "
                editable
                icon="calendar-week"
                position="is-bottom-right"
                :validation-rule="v$.userEdit.startDate"
                type="date"
              />
            </div>
            <div class="column">
              <b-field
                :label="$tf('userForm.endDate|Kilépés dátuma')"
                expanded
                label-position="on-border"
              >
                <f-datepicker
                  v-model="userEdit.endDate"
                  :placeholder="
                    $tf('userForm.endDate.placeholder|Kilépés dátuma')
                  "
                  editable
                  icon="calendar-week"
                  position="is-bottom-right"
                />
              </b-field>
            </div>
          </div>

          <div
            v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
            class="columns"
          >
            <div v-if="newUser" class="column is-full">
              <b-checkbox v-model="createsJiraUser"
                >{{
                  $tf("userForm.createJiraUser|Jira felhasználó létrehozása")
                }}
              </b-checkbox>
            </div>
            <div v-else class="column is-one-quarter">
              <b-button
                v-if="!userEdit.externalId"
                :loading="createsJiraUser"
                type="is-info"
                @click="
                  userData.email !== userEdit.email
                    ? openJiraConfirm()
                    : createJiraUser()
                "
                >{{
                  createsJiraUser
                    ? ""
                    : $tf(
                        "userForm.createJiraUser|Jira felhasználó létrehozása"
                      )
                }}
              </b-button>
            </div>
          </div>
          <div class="columns">
            <div
              v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
              class="column is-one-quarter"
            >
              <vuelidated-input
                v-model="userEdit.externalId"
                :disabled="!userEdit.loggingRequired || createsJiraUser"
                :label="$tf('userForm.jiraId|Jira ID')"
                :placeholder="
                  createsJiraUser
                    ? $tf('userForm.jiraId.generated|Később kerül generálásra')
                    : $tf('userForm.jiraId.placeholder|Jira azonosító')
                "
                :validation-rule="v$.userEdit.externalId"
                icon="fingerprint"
                label-position="on-border"
                ref-value="jiraId"
              />
            </div>
            <div class="column">
              <div class="checkbox-column">
                <b-field>
                  <b-checkbox
                    :disabled="createsJiraUser"
                    v-model="userEdit.loggingRequired"
                  >
                    {{
                      $tf(
                        "userForm.loggingRequired|Timesheetelésben részt vesz"
                      )
                    }}
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <div class="column">
              <vuelidated-input
                :label="$tf('userForm.parentCompany|Anyacég')"
                label-position="on-border"
                expanded
                v-model="userEdit.parentCompany"
                :disabled="!userEdit.external"
                :placeholder="$tf('userForm.parentCompany.placeholder|Anyacég')"
                :validation-rule="v$.userEdit.parentCompany"
              />
            </div>
            <div class="column">
              <div class="checkbox-column">
                <b-field>
                  <b-checkbox v-model="userEdit.external"
                    >{{ $tf("userForm.external|Külsős") }}
                  </b-checkbox>
                </b-field>
              </div>
            </div>
          </div>

          <h3 class="heading">
            {{ $tf("userForm.competencies.title|Kompetenciák") }}
          </h3>

          <vuelidated-input
            :label="$tf('userForm.title|Titulus')"
            label-position="on-border"
            v-model="userEdit.title"
            :placeholder="
              $tf('userForm.title.placeholder|Megjelenített titulus')
            "
            :validation-rule="v$.userEdit.title"
          />
          <div class="columns">
            <div class="column">
              <vuelidated-input
                :label="$tf('userForm.role|Szerepkör')"
                label-position="on-border"
                v-model="userEdit.roleId"
                :class="{ 'select-placeholder': userEdit.roleId == null }"
                :placeholder="
                  $tf('userForm.title.placeholder|Kérlek, válassz...')
                "
                expanded
                type="select"
                :validation-rule="v$.userEdit.roleId"
              >
                <template #select>
                  <option
                    v-for="indicator in indicators.filter(
                      (i) => i.type === USER_INDICATOR_TYPE_ENUM.ROLE
                    )"
                    :key="indicator.id"
                    :value="indicator.id"
                  >
                    {{ indicator.name }}
                  </option>
                </template>
              </vuelidated-input>
            </div>

            <div class="column">
              <vuelidated-input
                :label="$tf('userForm.seniority|Szenioritás')"
                label-position="on-border"
                v-model="userEdit.seniorityId"
                :class="{ 'select-placeholder': userEdit.seniorityId == null }"
                :placeholder="
                  $tf('userForm.seniority.placeholder|Kérlek, válassz...')
                "
                expanded
                type="select"
                :validation-rule="v$.userEdit.seniorityId"
              >
                <template #select>
                  <option
                    v-for="indicator in indicators.filter(
                      (i) => i.type === USER_INDICATOR_TYPE_ENUM.SENIORITY
                    )"
                    :key="indicator.id"
                    :value="indicator.id"
                  >
                    {{ indicator.name }}
                  </option>
                </template>
              </vuelidated-input>
            </div>

            <div class="column">
              <vuelidated-input
                :label="$tf('userForm.department|Terület')"
                label-position="on-border"
                v-model="userEdit.departmentId"
                :class="{
                  'select-placeholder': userEdit.departmentId == null,
                }"
                :placeholder="
                  $tf('userForm.department.placeholder|Kérlek, válassz...')
                "
                expanded
                type="select"
                :validation-rule="v$.userEdit.departmentId"
              >
                <template #select>
                  <option
                    v-for="indicator in indicators.filter(
                      (i) => i.type === USER_INDICATOR_TYPE_ENUM.DEPARTMENT
                    )"
                    :key="indicator.id"
                    :value="indicator.id"
                  >
                    {{ indicator.name }}
                  </option>
                </template>
              </vuelidated-input>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field
                :label="$tf('userForm.competencies|Kompetenciák')"
                expanded
                label-position="on-border"
              >
                <b-taginput
                  v-model="userEdit.competenciesEdit"
                  :allow-new="false"
                  :data="filteredCompetencies"
                  :open-on-focus="true"
                  :placeholder="
                    $tf(
                      'userForm.competencies.placeholder|Kompetenciák választása'
                    )
                  "
                  autocomplete
                  field="text"
                  icon="book"
                  @typing="
                    filterTags($event, competencies, 'filteredCompetencies')
                  "
                >
                </b-taginput>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <vuelidated-input
                :label="$tf('userForm.roles|Jogosultságok')"
                expanded
                label-position="on-border"
                v-model="userEdit.rolesEdit"
                :allow-new="false"
                :data="filteredRoles"
                :open-on-focus="true"
                :placeholder="
                  $tf('userForm.roles.placeholder|Jogkörök választása')
                "
                autocomplete
                field="text"
                icon="microchip"
                @typing="filterTags($event, roles, 'filteredRoles')"
                type="taginput"
                :validation-rule="v$.userEdit.rolesEdit"
              />
            </div>
          </div>

          <h3 class="heading">{{ $tf("userForm.misc.title|Egyéb") }}</h3>

          <div class="columns">
            <div class="column">
              <phone-input
                ref="contactInput"
                v-model="userEdit.contact"
                :label="$tf('userForm.contact|Telefonszám')"
                :placeholder="$tf('userForm.contact.placeholder|Telefonszám')"
                @validate="validContactInput = $event"
              />
            </div>

            <div class="column">
              <b-field
                :label="$tf('userForm.birthMonth|Születési hónap')"
                label-position="on-border"
              >
                <f-datepicker
                  v-model="userEdit.birthDate"
                  :placeholder="
                    $tf('userForm.birthMonth.placegholder|Születési hónap')
                  "
                  append-to-body
                  icon="calendar-week"
                  month-view
                  position="is-bottom-right"
                  type="month"
                >
                  <template #header>
                    <span> </span>
                  </template>
                </f-datepicker>
              </b-field>
            </div>
            <div class="column">
              <b-field
                :label="$tf('userForm.birthDay|Születési nap')"
                label-position="on-border"
              >
                <f-datepicker
                  v-model="userEdit.birthDate"
                  :day-names="[]"
                  :nearby-month-days="false"
                  :placeholder="
                    $tf('userForm.birthDay.placeholder|Születési nap')
                  "
                  append-to-body
                  day-view
                  icon="calendar-week"
                  position="is-bottom-right"
                >
                  <template #header>
                    <span> </span>
                  </template>
                </f-datepicker>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field
                :label="$tf('userForm.rank|Rangsor')"
                label-position="on-border"
                expanded
              >
                <b-input
                  v-model="userEdit.rank"
                  :placeholder="$tf('userForm.rank.placeholder|1234')"
                  type="number"
                />
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field
                :label="$tf('userForm.dataLink|Adatbekérő')"
                label-position="on-border"
              >
                <b-input
                  v-model="userEdit.dataLink"
                  :placeholder="
                    $tf('userForm.dataLink.placeholder|Adatbekérő URL')
                  "
                  icon="link"
                  type="url"
                >
                </b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field
                :label="$tf('userForm.flair|Bio')"
                expanded
                label-position="on-border"
              >
                <b-input
                  v-model="userEdit.flair"
                  :placeholder="
                    $tf(
                      'userForm.flair.placeholder|Egyedi megjegyzés / idézet / ars poetica'
                    )
                  "
                  icon="quote-left"
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <b-field :class="{ 'has-name': !!userEdit.avatar }" class="file">
            <b-upload
              v-model="userEdit.avatar"
              accept="image/*"
              class="file-label"
              rounded
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">{{
                  $tf("userForm.avatarUpload|Profilkép feltöltése")
                }}</span>
              </span>
              <span v-if="userEdit.avatar" class="file-name">
                {{ userEdit.avatar.name }}
              </span>
            </b-upload>
          </b-field>
        </form>
      </section>
      <footer class="modal-card-footer">
        <b-field class="ml-auto">
          <b-button class="mr-3" @click="cancel">
            {{ $tf("userModal.cancel|Mégse") }}
          </b-button>
          <b-field>
            <b-button type="is-info" @click="submit">
              {{ $tf("userModal.save|Mentés") }}
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
  </div>
</template>

<script>
import {
  correctWithTimezoneOffset,
  dateToMonthDay,
  deepCopy,
  normalize,
} from "@/utils/util";
import { getCurrentInstance, nextTick } from "vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import PhoneInput from "@/components/module/input/PhoneInput.vue";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import { email, maxLength, required, requiredIf } from "@vuelidate/validators";
import {
  ROLES,
  USER_INDICATOR_TYPE_ENUM,
  USER_PERMISSION_TYPE_ENUM,
  ISSUE_TRACKING_SYSTEM,
} from "@/utils/const";

export default {
  name: "UserModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  components: { FDatepicker, VuelidatedInput, PhoneInput },
  props: {
    newUser: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: false,
      default: null,
    },
    superadminCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  async mounted() {
    this.competencies = this.indicators
      .filter((i) => i.type === USER_INDICATOR_TYPE_ENUM.COMPETENCY)
      .map((i) => {
        return {
          value: i.id,
          text: i.name,
        };
      });
    this.roles = Object.values(ROLES).map((r) => {
      return {
        value: r.VALUE,
        text: r.TEXT,
      };
    });
    this.filteredRoles = this.roles;
    this.filteredCompetencies = this.competencies;
    if (!this.newUser) {
      this.userEdit = deepCopy(this.userData);
      if (this.userEdit.roles) {
        this.userEdit.rolesEdit = this.userEdit.roles
          .split(",")
          .map((role) => this.roles.filter((rr) => rr.value === role).shift());
        this.userEdit.avatar = null;
      }

      if (this.userEdit.competencies) {
        this.userEdit.competenciesEdit = this.userEdit.competencies.map(
          (competency) =>
            this.competencies.filter((cc) => cc.value === competency.id).shift()
        );
      }

      if (this.userEdit.startDate) {
        this.userEdit.startDate = new Date(this.userEdit.startDate);
      }

      if (this.userEdit.endDate) {
        this.userEdit.endDate = new Date(this.userEdit.endDate);
      }

      if (this.userEdit.birthDate) {
        this.userEdit.birthDate = new Date("2024-" + this.userEdit.birthDate); // 2024 because leap year
      }
    }
  },
  data() {
    return {
      normalize,
      USER_INDICATOR_TYPE_ENUM,
      USER_PERMISSION_TYPE_ENUM,
      ROLES,
      competencies: [],
      createsJiraUser: false,
      filteredRoles: [],
      filteredCompetencies: [],
      roles: [],
      userEdit: {
        avatar: null,
        name: null,
        dataLink: null,
        department: null,
        occupation: null,
        username: "",
        email: null,
        flair: null,
        id: null,
        phone: null,
        contact: null,
        external: false,
        portraitLink: null,
        locked: false,
        hidden: false,
        rank: null,
        roles: null,
        rolesEdit: [],
        competencies: null,
        competenciesEdit: [],
        parentCompany: null,
        loggingRequired: null,
        startDate: null,
        endDate: null,
        birthDate: null,
        aggregateHours: null,
        tier: null,
      },
      changes: [],
      validPhoneInput: true,
      validContactInput: true,
      ISSUE_TRACKING_SYSTEM,
    };
  },
  validations: {
    userEdit: {
      id: {
        maxLength: maxLength(255),
      },
      externalId: {
        required: requiredIf(function () {
          return (
            this.userEdit.loggingRequired &&
            this.issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum
          );
        }),
        maxLength: maxLength(255),
      },
      name: {
        required,
        maxLength: maxLength(255),
      },
      username: {
        required,
        maxLength: maxLength(255),
      },
      email: {
        required,
        maxLength: maxLength(255),
        email,
      },
      startDate: {
        required,
      },
      parentCompany: {
        required: requiredIf(function () {
          return this.userEdit.external;
        }),
        maxLength: maxLength(255),
      },
      title: {
        required,
        maxLength: maxLength(255),
      },
      roleId: {
        required,
      },
      seniorityId: {
        required,
      },
      departmentId: {
        required,
      },
      rolesEdit: {
        required,
      },
    },
  },
  watch: {
    createsJiraUser(val) {
      if (val) {
        this.userEdit.loggingRequired = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      env: "env/getField",
      indicators: "user_indicator/indicators",
      employees: "employee/employees",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),

    userId() {
      if (this.newUser) {
        return "{Generated}";
      } else {
        return this.userEdit.id;
      }
    },
  },
  methods: {
    filterTags(value, selectable, fieldKey) {
      let filtered = [];
      if (!value) {
        filtered = selectable;
      } else {
        filtered = selectable.filter((e) =>
          normalize(e.text).includes(normalize(value))
        );
      }
      this[fieldKey] = filtered;
    },
    async close() {
      this.$emit("fetch");
      this.cancel();
    },
    cancel() {
      this.$emit("close");
    },
    resetPassword() {
      this.$buefy.dialog.confirm({
        message: this.$tf(
          "userForm.passwordResetModal.message|Biztos helyre szeretnéd állítani {name} jelszavát?",
          { name: this.userEdit.name }
        ),
        onConfirm: () =>
          this.$store.dispatch(
            "census_user/resetAdminPassword",
            this.userEdit.id
          ),
      });
    },
    openJiraConfirm() {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "userForm.jiraConfirmModal.title|Jira fiók létrehozása"
        ),
        message: this.$tf(
          "userForm.jiraConfirmModal.message|A felhasználónak megváltozattad az email címét, de ezt még nem mentetted el. A Jira fiók az új {email} címmel fog létrejönni!",
          { email: this.userEdit.email }
        ),
        confirmText: this.$tf("userForm.jiraConfirmModal.confirm|Rendben"),
        cancelText: this.$tf("userForm.jiraConfirmModal.cancel|Mégsem"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => this.createJiraUser(),
      });
    },
    async createJiraUser() {
      this.createsJiraUser = true;
      let req = deepCopy(this.userData);
      req.email = this.userEdit.email;
      await this.$store.dispatch("employee/createJiraUser", req);
      this.userEdit.externalId = this.employees.find(
        (e) => e.id === this.userData.id
      ).externalId;
      this.createsJiraUser = false;
    },

    async submit() {
      this.v$.$touch();

      let phoneInput = this.$refs.phoneInput;
      if (phoneInput) {
        phoneInput.touch();
      }

      let contactInput = this.$refs.contactInput;
      if (contactInput) {
        contactInput.touch();
      }

      let changeEntryComponents = this.$refs.entries;
      let entryValues = [];

      if (
        !this.v$.$invalid &&
        phoneInput &&
        !phoneInput.v$.$invalid &&
        contactInput &&
        !contactInput.v$.$invalid
      ) {
        if (changeEntryComponents) {
          for (let index in changeEntryComponents) {
            let changeEntryComponent = changeEntryComponents[index];
            if (await changeEntryComponent.isInvalid()) {
              return;
            }
            entryValues.push(changeEntryComponent.getValues());
          }
        }

        const request = deepCopy(this.userEdit);

        if (request.rolesEdit) {
          request.roles = request.rolesEdit.map((r) => r.value).join(",");
        }
        delete request.rolesEdit;

        if (request.competenciesEdit) {
          request.competencies = request.competenciesEdit.map((c) => {
            return { id: c.value };
          });
        }
        delete request.competenciesEdit;

        if (request.startDate) {
          request.startDate = correctWithTimezoneOffset(
            new Date(request.startDate)
          );
        }

        if (request.birthDate) {
          request.birthDate = dateToMonthDay(request.birthDate);
        }

        if (request.endDate) {
          request.endDate = correctWithTimezoneOffset(
            new Date(request.endDate)
          );
        }

        request.phone = request.phone?.replace(/\s/g, "");
        request.contact = request.contact?.replace(/\s/g, "");

        const avatarForm = new FormData();
        if (request.avatar) {
          avatarForm.append("file", request.avatar);
          avatarForm.append("fileName", request.avatar.name);
          avatarForm.append("documentType", "USER_AVATAR");
        }

        if (this.newUser) {
          request.tenantId = this.tenantId;
          request.lang = this.env("DEFAULT_LANGUAGE");

          if (this.superadminCreate) {
            await this.$store
              .dispatch("employee/superadminCreate", {
                request,
                avatarForm,
              })
              .then(async () => {
                await this.close();
              });
          } else {
            await this.$store
              .dispatch("employee/create", {
                createUserRequest: request,
                createJiraUser: this.createsJiraUser,
                avatarForm,
              })
              .then(async () => {
                await this.close();
              })
              .catch((error) => {
                this.scrollToField(
                  `[data-field="${error?.response?.data?.error}"]`
                );
              });
          }
        } else {
          request.id = this.userId;
          await this.$store
            .dispatch("employee/update", {
              request,
              avatarForm,
            })
            .then(async () => {
              await this.close();
            });
        }
      } else {
        nextTick(() => {
          this.scrollToField('[data-has-error="true"]');
        });
      }
    },

    scrollToField(selector) {
      const target = document.querySelector(selector);
      if (target) {
        target.scrollIntoView({ behavior: "smooth", block: "center" });

        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              const elements = document.querySelectorAll(selector);

              elements.forEach((el) => {
                el.classList.add("animate-scale");

                el.addEventListener(
                  "animationend",
                  () => {
                    el.classList.remove("animate-scale");
                  },
                  { once: true }
                );
              });

              observer.disconnect();
            }
          },
          { threshold: 1.0 }
        );

        observer.observe(target);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.animate-scale {
  animation: scale-up-down 0.6s ease;
}

@keyframes scale-up-down {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
</style>
