<template>
  <b-dropdown
    :append-to-body="appendToBody"
    :scrollable="scrollable"
    :max-height="maxHeight"
    :class="{ 'fill-area': fillAreaOnDropdown, 'max-width-100': true }"
    @active-change="visibleList = $event"
    expanded
  >
    <template #trigger="{ active }">
      <b-input
        v-model="search"
        :placeholder="$tf('multiselectDropdown.searchPlaceholder|Keresés...')"
        :icon-right="icon"
        @icon-right-click="$emit('iconRightClick')"
        icon-right-clickable
        :icon="active ? 'chevron-up' : 'chevron-down'"
        icon-clickable
        class="p-0"
        ref="searchInput"
        v-if="visibleList && searchable"
        style="width: 100%"
      />
      <b-button v-else class="filter-dropdown-field max-width-100">
        <div
          class="is-flex is-align-items-center is-justify-content-space-between has-gap-1 w-100 max-width-100"
        >
          <div>
            <b-icon :icon="active ? 'chevron-up' : 'chevron-down'" />
          </div>
          <template v-if="hideSelected">
            <div>
              <p class="filter-placeholder">
                {{
                  $tf(
                    "multiselectDropdown.placeholder|Válassz a listából vagy gépelj a kereséshez..."
                  )
                }}
              </p>
            </div>
          </template>
          <template v-else>
            <div
              class="is-flex-grow-1 overflow-hidden text-overflow-ellipsis max-width-100 px-2"
            >
              {{ label }}
            </div>
          </template>
          <div class="is-flex is-align-items-center has-gap-2 pr-1">
            <div v-if="selectedItems.length > 0" class="filter-dropdown-badge">
              {{ selectedItems.length }}
            </div>
          </div>
          <div v-if="icon" class="is-justify-content-end">
            <b-icon :icon="icon" @click="$emit('iconRightClick')" />
          </div>
        </div>
      </b-button>
    </template>
    <div class="filter-dropdown-content">
      <slot name="select-all">
        <template
          v-if="
            hasSelectAllOption &&
            filteredItems.length !== 0 &&
            filteredItems.length === items.length
          "
        >
          <div class="is-flex is-align-items-center">
            <b-checkbox
              :indeterminate="isGroupIndeterminate"
              :checked="isGroupComplete"
              :modelValue="isGroupComplete"
              @update:modelValue="handleGroupSelection"
            >
              {{ $tf("multiselectDropdown.all|Mind") }}
            </b-checkbox>
          </div>
        </template>
      </slot>
      <template v-if="filteredItems.length === 0">
        <slot name="empty">
          <div
            class="is-flex has-gap-2 has-text-centered is-justify-content-center"
          >
            <b-icon icon="frown" />
            <p class="is-size-5">
              {{ $tf("multiselectDropdown.emptyList|Nincs találat") }}
            </p>
          </div>
        </slot>
      </template>
      <slot>
        <div v-for="(item, id) in filteredItems" :key="id">
          <b-checkbox
            v-model="computedItems"
            :native-value="item[identifierField]"
            :disabled="isDisabled(item)"
            @update:modelValue="input"
          >
            <p v-if="useTf">{{ $tf(item[nameField]) }}</p>
            <p v-else>{{ item[nameField] }}</p>
          </b-checkbox>
        </div>
      </slot>
    </div>
  </b-dropdown>
</template>

<script>
import { nextTick } from "vue";

export default {
  name: "MultiselectDropdown",
  props: {
    noneSelectedText: {
      type: String,
      default: "-",
    },
    icon: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    identifierField: {
      type: String,
      default: "identifier",
    },
    nameField: {
      type: String,
      default: "name",
    },
    modelValue: {
      type: Array,
      required: false,
      default: () => [],
    },
    labelLength: {
      type: Number,
      default: 0,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    hasSelectAllOption: {
      type: Boolean,
      default: false,
    },
    fillAreaOnDropdown: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: [String, Number],
      default: "200px",
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    useTf: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabledFunction: {
      type: Function,
      default: () => false,
    },
  },
  data() {
    return {
      selectedItems: [],
      search: "",
      visibleList: false,
    };
  },
  mounted() {
    if (this.modelValue) {
      this.selectedItems = this.modelValue;
    }
  },
  watch: {
    modelValue() {
      if (this.modelValue && this.modelValue !== this.selectedItems) {
        this.selectedItems = this.modelValue;
      }
    },
    visibleList() {
      if (this.visibleList) {
        nextTick(() => {
          this.$refs.searchInput?.focus();
        });
      } else {
        this.search = "";
      }
    },
  },
  computed: {
    computedItems: {
      get() {
        return this.selectedItems;
      },
      set(v) {
        this.selectedItems = v;
        this.$emit("update:modelValue", this.selectedItems);
      },
    },
    filteredItems() {
      return this.items.filter((item) => {
        return item[this.nameField]
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    label() {
      if (this.selectedItems.length === 0) {
        return this.noneSelectedText;
      } else if (this.selectedItems.length === this.items.length) {
        return this.$tf("multiselectDropdown.all|Mind");
      } else {
        let joinedItems = this.selectedItems
          .map((id) => {
            const item = this.items.find(
              (t) => t[this.identifierField]?.toString() === id?.toString()
            );
            return item
              ? this.useTf
                ? this.$tf(item[this.nameField])
                : item[this.nameField]
              : undefined;
          })
          .filter((item) => item !== undefined)
          .map((item) =>
            this.labelLength > 0 && item.length > this.labelLength
              ? item.slice(0, this.labelLength) + "..."
              : item
          )
          .join(", ");

        return joinedItems;
      }
    },

    isGroupIndeterminate() {
      return this.selectedItems.length > 0 && !this.isGroupComplete;
    },
    isGroupComplete() {
      return this.selectedItems.length === this.items.length;
    },
  },
  methods: {
    input() {
      this.$emit("update:modelValue", this.selectedItems);
    },
    handleGroupSelection(value) {
      if (value) {
        this.selectedItems = this.items.map(
          (item) => item[this.identifierField]
        );
      } else {
        this.selectedItems = [];
      }
      this.input();
    },
    isDisabled(item) {
      return this.disabledFunction(item);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.filter-dropdown-content {
  padding: 0 12px;

  div {
    display: flex;
    align-items: center;
    padding: 6px 0;

    &:not(:first-child) {
      border-top: 1px solid $grey-lighter;
    }
  }
}

.filter-dropdown-badge {
  padding-left: 6px;
  padding-right: 6px;
  color: $white;
  background-color: $primary;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 700;
}

.filter-placeholder {
  color: #808082;
  font-weight: 400;
}
</style>

<style lang="scss">
.fill-area {
  &.dropdown {
    flex-direction: column;

    .dropdown-menu {
      position: relative !important;
    }
  }
}

@import "~@/assets/scss/colors.scss";

.filter-dropdown-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $grey-lighter;
  border-radius: 8px;
  padding: calc(0.5em - 1px) calc(1em - 1px) !important;
  cursor: pointer;
  span {
    width: 100%;
    &:not(.icon) {
      max-width: 100%;
    }
  }
}
</style>
