<template>
  <section v-if="!loading">
    <div class="container ralloc-container">
      <p class="ralloc-container-title">
        {{ $tf("rallocPage.employeeUtilization|Dolgozók utilizációja") }}
      </p>
      <div class="is-flex is-align-items-center has-gap-2 mb-3">
        <div class="is-flex-4">
          <b-field
            :label="$tf('resource.utilization.filter.employee|Dolgozók')"
            label-position="on-border"
          >
            <multiselect-dropdown
              :modelValue="selectedEmployees"
              :items="employees"
              append-to-body
              identifier-field="id"
              name-field="name"
              use-tf
              expanded
              searchable
              clearable
              scrollable
              max-height="300"
              icon="circle-xmark"
              :disabled-function="isUserSelectionDisabled"
              @iconRightClick="filters.selectedEmployees = []"
              @update:modelValue="updateSelectedEmployees"
            >
              <template #empty>{{
                $tf("resource.utilization.filter.empty|Nincs találat")
              }}</template>
            </multiselect-dropdown>
          </b-field>
        </div>
        <div class="is-flex-2">
          <b-field
            :label="$tf('resource.utilization.filter.team|Csapatok')"
            label-position="on-border"
          >
            <multiselect-dropdown
              id-field="identifier"
              v-model="filters.team"
              clearable
              :items="teams"
              append-to-body
              identifier-field="id"
              name-field="name"
              use-tf
              expanded
              searchable
              scrollable
              max-height="300"
              icon="circle-xmark"
              @iconRightClick="filters.team = []"
            />
          </b-field>
        </div>
        <!--      <div class="column">-->
        <!--        <b-field :label="$tf('resource.utilization.filter.roles|Munkakör')">-->
        <!--          <multiselect-dropdown-->
        <!--            v-model="filters.roles"-->
        <!--            :items="roles"-->
        <!--            append-to-body-->
        <!--            has-select-all-option-->
        <!--            identifier-field="id"-->
        <!--            name-field="name"-->
        <!--            use-tf-->
        <!--          />-->
        <!--        </b-field>-->
        <!--      </div>-->
        <!--      <div class="column">-->
        <!--        <b-field-->
        <!--          :label="$tf('resource.utilization.filter.seniority|Szenioritás')"-->
        <!--        >-->
        <!--          <multiselect-dropdown-->
        <!--            v-model="filters.seniorities"-->
        <!--            :items="seniorities"-->
        <!--            append-to-body-->
        <!--            has-select-all-option-->
        <!--            identifier-field="name"-->
        <!--            name-field="name"-->
        <!--            use-tf-->
        <!--          />-->
        <!--        </b-field>-->
        <!--      </div>-->
        <div class="is-flex-1">
          <b-field
            :label="$tf('resource.utilization.filter.utilization|Utilizáltság')"
            label-position="on-border"
          >
            <multiselect-dropdown
              v-model="filters.utilizations"
              :items="utilizations"
              append-to-body
              has-select-all-option
              identifier-field="id"
              name-field="name"
              use-tf
            />
          </b-field>
        </div>
      </div>
      <div class="is-flex is-align-items-center has-gap-2">
        <div class="is-flex-4">
          <b-field
            :label="$tf('resource.utilization.numberOfWeeks|Hetek száma')"
            label-position="on-border"
          >
            <b-select v-model="display.numberOfWeeks">
              <option :value="4">
                {{
                  $tf("resource.utilization.numberOfWeeks|{number} hét", {
                    number: 4,
                  })
                }}
              </option>
              <option :value="8">
                {{
                  $tf("resource.utilization.numberOfWeeks|{number} hét", {
                    number: 8,
                  })
                }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div>
          <div
            v-if="extendedAssociations.length > 0"
            class="ml-4 ralloc-container-icons"
          >
            <b-icon
              class="cursor-pointer mr-2"
              icon="chevron-left"
              size="is-small"
              @click="minusWeek"
            />
            <span
              v-if="displayedWeeks.length"
              class="mr-2 ralloc-container-icons-weeks"
            >
              {{
                `${displayedWeeks[0].week}-${
                  displayedWeeks[displayedWeeks.length - 1].week
                }${$tf("rallocPage.nthWeek|. hét")}`
              }}
            </span>
            <b-icon
              class="cursor-pointer"
              icon="chevron-right"
              size="is-small"
              @click="plusWeek"
            />
            <b-icon
              class="cursor-pointer ralloc-container-icons-undo"
              icon="undo"
              size="is-small"
              @click="resetWeek"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="is-flex is-justify-content-end has-gap-6 mb-4">
        <div class="is-flex is-align-items-center has-gap-2">
          <div>
            <p :class="{ 'has-text-primary': display.numbers === 'NUMBERS' }">
              {{
                $tf(
                  "rallocPage.employeeUtilization.display.numbers.numbers|Kitöltöttség"
                )
              }}
            </p>
          </div>
          <div class="is-flex is-justify-content-center">
            <b-switch
              v-model="display.numbers"
              true-value="DIFFERENCE"
              false-value="NUMBERS"
              type="is-info"
              passive-type="is-info"
              class="mr-0"
            ></b-switch>
          </div>
          <div>
            <p
              :class="{ 'has-text-primary': display.numbers === 'DIFFERENCE' }"
            >
              {{
                $tf(
                  "rallocPage.employeeUtilization.display.numbers.difference|Túltervezés"
                )
              }}
            </p>
          </div>
        </div>
        <div class="is-flex is-align-items-center has-gap-2">
          <div>
            <p :class="{ 'has-text-primary': display.time === 'DAYS' }">
              {{
                $tf(
                  "rallocPage.employeeUtilization.display.time.days|Munkanapok"
                )
              }}
              <b-icon icon="sun"></b-icon>
            </p>
          </div>
          <div class="is-flex is-justify-content-center">
            <b-switch
              v-model="display.time"
              true-value="HOURS"
              false-value="DAYS"
              type="is-info"
              passive-type="is-info"
              class="mr-0"
            ></b-switch>
          </div>
          <div>
            <p :class="{ 'has-text-primary': display.time === 'HOURS' }">
              <b-icon icon="clock"></b-icon>
              {{
                $tf(
                  "rallocPage.employeeUtilization.display.time.hours|Munkaórák"
                )
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        id="utilization-table-container"
        class="utilization-table-container overflow-x-auto"
        ref="tableWrapper"
      >
        <b-table
          :data="extendedAssociations"
          class="ralloc-container-employees-table"
          detailed
          detail-key="employeeId"
          :opened-detailed="openDetails"
          v-if="extendedAssociations?.length > 0"
          :loading="fetchingAllocations"
        >
          <b-table-column
            field="employee.name"
            :label="$tf('resource.utilization.table.employee|Név')"
            v-slot="prop"
          >
            <UserInfo display-mode="long" :user="prop.row.employee">
              <template
                v-if="getTeamList(prop.row.employee)[0]"
                #additional-information
              >
                <b-tag type="is-default" class="team-tag">{{
                  getTeamList(prop.row.employee)[0]
                }}</b-tag>
                <b-tag
                  v-if="getTeamList(prop.row.employee)[1]"
                  type="is-default"
                  >{{ getTeamList(prop.row.employee)[1] }}</b-tag
                >
                <span
                  v-if="getTeamList(prop.row.employee).length > 2"
                  class="team-tag-plus"
                >
                  +{{ getTeamList(prop.row.employee).length - 2 }}
                </span>
              </template>
            </UserInfo>
          </b-table-column>
          <b-table-column
            field="employee.roles"
            :label="
              $tf(
                'resource.utilization.table.resourceUtilization|Erőforrás kihasználtség'
              )
            "
            v-slot="prop"
            :visible="false"
          >
            <!--            TODO: legyen majd visible-->
            >
            <UtilizationEmployeeBrief
              :data="prop.row"
            ></UtilizationEmployeeBrief>
          </b-table-column>
          <b-table-column
            v-for="(week, index) in displayedWeeks"
            field="weekData.week"
            :key="`weekData.week-${index}`"
            :header-class="
              week.week === new Date().getWeek() &&
              week.year === new Date().getUTCFullYear()
                ? 'ralloc-container-header-now'
                : ''
            "
            :meta="week.week"
            :td-attrs="getWeekTdAttributes"
          >
            <template #header>
              <div>
                <b>
                  {{ `${week.week} ${$tf("rallocPage.nthWeek|. hét")}` }}
                </b>
                <span
                  v-if="
                    week.week === new Date().getWeek() &&
                    week.year === new Date().getUTCFullYear()
                  "
                  class="ralloc-container-header-this-week"
                >
                  {{ $tf("rallocPage.thisWeek| (e hét)") }}
                </span>
              </div>
              <div>
                {{ dateRange(week.year, week.week) }}
              </div>
            </template>
            <template v-slot="props">
              <div class="is-flex has-gap-2 is-align-items-center">
                <div>
                  <p class="has-font-weight-500 is-size-4">
                    {{ resolveAllocatedHours(props, week.week) }}
                  </p>
                </div>
                <template v-if="display.numbers === 'NUMBERS'">
                  <div><p class="is-size-4">/</p></div>
                  <div>
                    <p class="is-size-6">
                      {{ resolveExpectedHours(props, week.week) }}
                    </p>
                  </div>
                </template>
              </div>
            </template>
          </b-table-column>
          <template #detail="prop">
            <EmployeeUtilizationDetails
              :data="prop.row"
              :max-width="tableWidth"
              :to-date="toDate"
              :from-date="fromDate"
              @fetch-allocations="fetchAssociations"
            ></EmployeeUtilizationDetails>
          </template>
        </b-table>
        <div v-else>
          <div class="ralloc-container-empty-list"></div>
          <div class="ralloc-container-empty-placeholder">
            {{
              $tf(
                "resource.utilization.projects.empty|A megjelenítéshez válassz legalább egy projektet!"
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import debounce from "lodash.debounce";
import UtilizationEmployeeBrief from "@/components/ralloc/UtilizationEmployeeBrief.vue";
import EmployeeUtilizationDetails from "@/components/ralloc/EmployeeUtilizationDetails.vue";
import UserInfo from "@/components/module/info/UserInfo.vue";
import {
  addDay,
  convertHoursToMWD,
  deepCopy,
  formatDate,
  localFetch,
  numberify,
  subtractDay,
  getWeekNumber,
  getISOWeekYearAndNumber,
  getWeekDataBetween,
} from "@/utils/util";
// import IndicatorTag from "@/components/module/info/IndicatorTag.vue";
import moment from "moment";

export default defineComponent({
  name: "UtilizationEmployees",
  components: {
    // IndicatorTag,
    EmployeeUtilizationDetails,
    UtilizationEmployeeBrief,
    MultiselectDropdown,
    UserInfo,
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  async mounted() {
    await this.fetchData();
    this.setDefaultDates();
    this.loading = false;
    this.$nextTick(() => {
      this.tableWidth = this.$refs.tableWrapper?.clientWidth;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  data() {
    return {
      loading: true,
      filters: {
        team: [],
        selectedEmployees: [],
        automaticallySelectedEmployees: [],
        roles: [],
        utilizations: [],
        seniorities: [],
      },
      tableWidth: 0,
      display: {
        numbers: "NUMBERS",
        time: "DAYS",
        numberOfWeeks: 4,
      },
      fromDate: undefined,
      toDate: undefined,
      storedLocale: localFetch("stored_locale") ?? "hu",
      openDetails: [],
      fetchingAllocations: false,
    };
  },
  computed: {
    ...mapGetters({
      teams: "census_team/accessibleTeams",
      employees: "employee/loggingEmployees",
      associations: "association/associationsByUsers",
      indicators: "user_indicator/indicators",
    }),
    roles() {
      return this?.indicators.filter((indicator) => indicator.type === "ROLE");
    },
    seniorities() {
      return this.indicators.filter(
        (indicator) => indicator.type === "SENIORITY"
      );
    },
    utilizations() {
      return [
        {
          id: "UNDERUTILIZED",
          name: "resource.utilization.filter.utilization.underutilized|Alulhasznált",
        },
        {
          id: "OVERUTILIZED",
          name: "resource.utilization.filter.utilization.overutilized|Túlhasznált",
        },
      ];
    },
    extendedAssociations() {
      return this.associations.map((association) => ({
        employee: this.getEmployee(association.employeeId),
        expectedHoursSum: this.getExpectedHoursSum(association),
        allocatedHoursSum: this.getAllocatedHoursSum(association),
        ...association,
      }));
    },

    displayedWeeks() {
      if (!this.fromDate || !this.toDate) {
        return [];
      }

      return getWeekDataBetween(this.fromDate, this.toDate);
    },
    selectedEmployees() {
      return this.filters.selectedEmployees.concat(
        ...this.filters.automaticallySelectedEmployees
      );
    },
  },
  watch: {
    selectedEmployees: {
      handler: debounce(async function () {
        await this.fetchAssociations();
      }, 500),
      deep: true,
    },
    "filters.team": {
      handler: debounce(async function () {
        this.filters.automaticallySelectedEmployees = [];
        if (this.filters.team) {
          this.filters.team.forEach((selectedTeam) => {
            this.teams.forEach((team) => {
              if (team.id === selectedTeam) {
                this.filters.automaticallySelectedEmployees.push(
                  ...team.employees
                    .map((e) => e.userId)
                    .filter(
                      (e) =>
                        !this.filters.automaticallySelectedEmployees.includes(e)
                    )
                );
              }
            });
          });
        }
      }, 500),
      deep: true,
    },
    "filters.utilizations": {
      handler: debounce(async function () {
        await this.fetchAssociations();
      }, 500),
      deep: true,
    },
    "display.numberOfWeeks": {
      handler(val) {
        this.toDate = this.fromDate.addDays(val * 7 - 1);
        this.fetchAssociations();
      },
    },
  },
  methods: {
    deepCopy,
    formatDate,
    addDay,
    subtractDay,
    getTeamList(employee) {
      if (!this.teams) {
        return [];
      }

      return this.teams
        .filter((data) =>
          data.employees.map((e) => e.employeeId).includes(employee.id)
        )
        .map((data) => data.name);
    },
    dateRange(year, weekNumber) {
      const formattedWeek = weekNumber.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const startOfWeek = moment(`${year}W${formattedWeek}`)
        .startOf("isoWeek")
        .locale(this.storedLocale);
      const endOfWeek = moment(`${year}W${formattedWeek}`)
        .endOf("isoWeek")
        .locale(this.storedLocale);
      return `${startOfWeek.format("MMM D")}. - ${endOfWeek.format("MMM D")}.`;
    },
    getMondayAndFriday(year, weekNumber) {
      const firstDayOfYear = new Date(year, 0, 1);
      const firstDayOfWeek = firstDayOfYear.getDay();
      const dayOffset = firstDayOfWeek === 0 ? 1 : 8 - firstDayOfWeek;

      const monday = new Date(year, 0, 1 + dayOffset + (weekNumber - 1) * 7);
      const friday = new Date(monday);
      friday.setDate(monday.getDate() + 4);

      return { monday, friday };
    },
    async fetchData() {
      await this.$store.dispatch("census_team/fetchAccessibleTeams");
      await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
    },
    async fetchAssociations() {
      this.fetchingAllocations = true;
      await this.$store.dispatch("association/getForUsers", {
        employees: this.selectedEmployees,
        from: this.fromDate,
        to: this.toDate,
        utilizations: this.filters.utilizations,
      });
      this.fetchingAllocations = false;
    },
    getEmployee(employeeId) {
      return this.employees.find((e) => e.id === employeeId);
    },
    getExpectedHoursSum(association) {
      return association.weekData
        .map((a) => a.expectedHours)
        .reduce((a, b) => a + b, 0);
    },
    getAllocatedHoursSum(association) {
      return association.weekData
        .map((a) => a.allocatedHours)
        .reduce((a, b) => a + b, 0);
    },
    resolveExpectedHours(props, week) {
      const hours = props.row.weekData.find(
        (data) => data.week === week
      )?.expectedHours;
      return this.display.time === "HOURS" ? hours : convertHoursToMWD(hours);
    },
    resolveAllocatedHours(props, week) {
      let hours = props.row.weekData.find(
        (data) => data.week === week
      )?.allocatedHours;
      hours = this.display.time === "HOURS" ? hours : convertHoursToMWD(hours);
      return this.display.numbers === "DIFFERENCE"
        ? numberify(hours - this.resolveExpectedHours(props, week), true)
        : hours;
    },
    getWeekTdAttributes({ weekData }, { meta }) {
      const weekInfo = weekData.find((data) => data.week === meta);
      return {
        class: [
          "ralloc-container-cell",
          this.getWeekClass(
            weekInfo ?? {
              expectedHours: 1,
              allocatedHours: 0,
            }
          ),
        ],
      };
    },
    getWeekClass(weekInfo) {
      const ratio = weekInfo.allocatedHours / weekInfo.expectedHours;

      if (ratio < 0.2) {
        return "ralloc-container-cell-100";
      }
      if (ratio >= 0.2 && ratio < 0.4) {
        return "ralloc-container-cell-80";
      }
      if (ratio >= 0.4 && ratio < 0.6) {
        return "ralloc-container-cell-60";
      }
      if (ratio >= 0.6 && ratio < 0.8) {
        return "ralloc-container-cell-40";
      }
      if (ratio >= 0.8 && ratio < 1) {
        return "ralloc-container-cell-20";
      }
      if (ratio > 1) {
        return "ralloc-container-cell-error";
      }
      return "";
    },
    resizeHandler() {
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    },
    plusWeek() {
      this.fromDate = new Date(this.addDay(this.fromDate, 7));
      this.toDate = new Date(this.addDay(this.toDate, 7));
      this.fetchAssociations();
    },
    minusWeek() {
      this.fromDate = new Date(this.subtractDay(this.fromDate, 7));
      this.toDate = new Date(this.subtractDay(this.toDate, 7));
      this.fetchAssociations();
    },
    resetWeek() {
      this.setDefaultDates();
      this.fetchAssociations();
    },
    setDefaultDates() {
      this.fromDate = new Date(
        new Date().setDate(
          new Date().getDate() -
            (new Date().getDay() === 0 ? 6 : new Date().getDay() - 1)
        )
      );

      this.toDate = this.fromDate.addDays(this.display.numberOfWeeks * 7 - 1);
    },
    updateSelectedEmployees(employees) {
      employees.forEach((employee) => {
        if (!this.selectedEmployees.includes(employee)) {
          this.filters.selectedEmployees.push(employee);
        }
      });
    },
    isUserSelectionDisabled(item) {
      return this.filters.automaticallySelectedEmployees.includes(item.id);
    },
  },
});
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.ralloc-container {
  &-employees-table {
    table {
      border-spacing: 8px 8px !important;
      border-collapse: separate;
    }

    th {
      div {
        text-align: left !important;
        justify-content: left;
        color: $custom-dark-blue !important;
      }

      box-shadow: 10px 0 $grey-lightest;
    }

    th:last-of-type {
      box-shadow: none !important;
    }

    .ralloc-container-cell {
      border: 1px solid $grey-lighter !important;
      color: $custom-dark-blue !important;
      cursor: pointer;
      border-radius: 12px;
      text-align: center !important;
      font-size: 14px !important;

      &-20 {
        color: $always-black !important;
        background: #def4f4;
      }
      &-40 {
        color: $always-black !important;
        background: #bde9e9;
      }
      &-60 {
        color: $always-black !important;
        background: #9bdddd;
      }
      &-80 {
        color: $always-white !important;
        background: #7ad2d2;
      }
      &-100 {
        color: $always-white !important;
        background: #59c7c7;
      }
      &-error {
        color: $always-black !important;
        background: #e9859a;
      }
    }

    td:nth-child(1),
    td:nth-child(2)
    // td:nth-child(3)  TODO: ha visszajön az erőforrás kihasználtség akkor kell
    {
      border: none !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      text-align: left;
    }

    .detail {
      border-radius: 12px;

      .table tr.detail {
        background: $custom-lighter-blue !important;
        border-radius: 12px;
      }

      td[colspan="8"] {
        background: $custom-lighter-blue !important;
        border-radius: 12px;
      }
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: $custom-dark-blue;
  }

  &-empty-list {
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: $grey-lightest;
    height: 30px;
  }

  &-empty-placeholder {
    height: 100%;
    width: 100%;
    padding: 21px;
    border-radius: 10px;
    border: 1px solid $grey-lightest;
    color: $grey-lighter;
    margin-top: 10px;
    text-align: center;
  }

  &-icons {
    &-weeks {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: $custom-dark-blue;
    }

    &-undo {
      margin-left: 16px;
      color: $blue;
    }
  }

  &-header {
    &-this-week {
      text-transform: lowercase !important;
    }
  }
}

.team-tag {
  margin-right: 4px;
}

.team-tag-plus {
  color: $white;
  background: $forecast-secondary;
  font-size: 12px;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.table-height-hack td {
  height: 1px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
