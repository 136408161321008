<template>
  <div>
    <div v-if="weekly.noData" class="utilization-top-row">
      {{ $tf("rallocPage.projectUtilization.noAllocation|N/A") }}
    </div>
    <div v-if="!weekly.noData" class="utilization-top-row">
      {{ allocatedHours }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { convertHoursToMWD, percentify, workdayify } from "@/utils/util";

export default defineComponent({
  name: "WeeklyProjectUtilization",
  props: {
    weekly: {
      type: Object,
      required: true,
    },
    future: {
      type: Boolean,
      required: true,
    },
    timeFormat: {
      type: String,
      required: true,
    },
  },
  methods: {
    percentify,
    workdayify,
  },
  computed: {
    allocatedHours() {
      return this.timeFormat === "HOURS"
        ? this.weekly.allocatedHours + " h"
        : workdayify(convertHoursToMWD(this.weekly.allocatedHours));
    },
  },
});
</script>

<style scoped lang="scss"></style>
