import network from "../../../../utils/network";
import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";
import FileSaver from "file-saver";

let logger = newLogger("Enterprise-ProjectStore");
let generateRequestId = function () {
  return Math.floor(Math.random() * 100000);
};

const urls = {
  projectData: "/enterprise/project",
  projectRefresh: (projectType, id) =>
    `/enterprise/projects/refresh/${projectType}/${id}`,
  projectExport: (identifier, onlyTime, onlyIssues) =>
    `/enterprise/project/export/${identifier}?onlyTime=${onlyTime}&onlyIssues=${onlyIssues}`,
  projectUpdate: "/enterprise/project",
  projectCreate: "/enterprise/project",
  tigData: "/enterprise/tig",
  tigUpdate: "/enterprise/tig",
  tigCreate: "/enterprise/project",
  activeTigs: "enterprise/projects/active/tigs",
  override: "/enterprise/project/override",
  expense: "/enterprise/project/expense",
  invoice: "/enterprise/invoices",
  projectInvoice: "/enterprise/project/invoice",
  statusChange: (identifier) => `/enterprise/project/${identifier}/status`,
  archive: (identifier) => `/enterprise/project/${identifier}/archive`,
  progressChange: (identifier) => `/enterprise/project/${identifier}/progress`,
  document: "byte-barn/document",
  documents: "byte-barn/documents",
  projectFile: (fileId) => `byte-barn/file/download/${fileId}`,
  star: (projectType, identifier) =>
    `/enterprise/project/star/${projectType}/${identifier}`,
  projectMetadata: (identifier, projectType) =>
    `/enterprise/project/metadata/${identifier}?projectType=${projectType}`,
  syncProjectMetadata: (prefix) =>
    `/enterprise/project/metadata/sync/${prefix}`,
  epics: `/enterprise/epics`,
  syncEpics: `/enterprise/epics/sync`,
  createTigTargeting: (tigId) => `/enterprise/tig/${tigId}/targeting`,
};

const initState = () => {
  return {
    projectData: {},
    aciveTigs: [],
    tigData: {},
    invoices: [],
    projectMetadata: {},
    epics: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchProjectData: async function (
    { commit, dispatch },
    { projectIdentifier, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.projectData}?identifier=${projectIdentifier}`,
        null,
        true,
        dispatch,
        "Projekt adatainak betöltése...",
        0,
        force
      );
      commit("saveProjectData", {
        identifier: projectIdentifier,
        data: data,
      });
      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
        throw err;
      }
    }
  }.bind(storeCore),
  fetchActiveTigs: async function ({ commit, dispatch }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.activeTigs,
        params,
        true,
        dispatch,
        "Aktív mérföldkövek betöltése..."
      );
      commit("saveActiveTigs", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchTigData: async function ({ commit, dispatch }, tigIdentifier) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.tigData}?identifier=${tigIdentifier}`,
        null,
        true,
        dispatch,
        "TIG adatainak betöltése..."
      );
      commit("saveTigData", {
        identifier: tigIdentifier,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  refresh: async function ({ dispatch }, { id, projectType } = {}) {
    try {
      await this.remoteRequest(
        "get",
        urls.projectRefresh(projectType, id),
        null,
        false,
        dispatch,
        "projects.refresh.flair|Projekt szinkronizálása Jirából",
        Number.MAX_SAFE_INTEGER,
        true,
        false,
        true,
        {
          progress: "projects.refresh.progress|Szinkronizálás folyamatban...",
          fail: "projects.refresh.fail|Szinkronizálás sikertelen!",
          success: "projects.refresh.success|Szinkronizálás sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  export: async (
    { dispatch },
    { identifier: identifier, onlyTime: onlyTime, onlyIssues: onlyIssues }
  ) => {
    let requestId = generateRequestId();
    dispatch(
      "loading/startLoading",
      {
        id: requestId,
        type: "GET",
        url: urls.projectExport(identifier, onlyTime, onlyIssues),
        flair: "Projekt exportálása...",
      },
      { root: true }
    );

    try {
      const axios = await network.excelConnection();
      const { data } = await axios.get(
        urls.projectExport(identifier, onlyTime, onlyIssues)
      );

      dispatch(
        "loading/finishLoading",
        { id: requestId, result: true },
        { root: true }
      );

      return data;
    } catch (err) {
      logger.error(err);
      dispatch(
        "loading/finishLoading",
        { id: requestId, result: false },
        { root: true }
      );
    }
  },
  createProject: async function ({ dispatch }, { projectData, formData }) {
    if (Array.isArray(projectData.technologies))
      projectData.technologies = projectData.technologies.join(",");
    if (Array.isArray(projectData.laborTypes))
      projectData.laborTypes = projectData.laborTypes.join(",");

    try {
      await this.remoteRequest(
        "post",
        `${urls.projectCreate}`,
        projectData,
        false,
        dispatch,
        "projects.create.flair|Projekt létrehozása",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.create.progress|Új projekt létrehozása folyamatban...",
          fail: "projects.create.fail|Új projekt létrehozása sikertelen!",
          success: "projects.create.success|Új projekt létrehozésa sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveProject: async function ({ dispatch }, { projectData, formData }) {
    if (Array.isArray(projectData.technologies))
      projectData.technologies = projectData.technologies.join(",");
    if (Array.isArray(projectData.laborTypes))
      projectData.laborTypes = projectData.laborTypes.join(",");

    try {
      let response = await this.remoteRequest(
        "put",
        `${urls.projectUpdate}`,
        projectData,
        false,
        dispatch,
        "projects.save.flair|Projekt adatainak mentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.save.progress|Projekt adatainak mentése folyamatban...",
          fail: "projects.save.fail|Projekt adatainak mentése sikertelen!",
          success: "projects.save.success|Projekt adatainak mentése sikeres!",
        }
      );
      if (formData != null) {
        formData.append("identifier", projectData.id);
        await this.remoteRequest(
          "post",
          `${urls.documents}`,
          formData,
          false,
          dispatch,
          "Dokumentumok feltöltése..."
        );
      }
      return true;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return false;
    }
  }.bind(storeCore),
  createTig: async function ({ dispatch }, { projectIdentifier, tig }) {
    try {
      return await this.remoteRequest(
        "post",
        `${urls.tigCreate}/${projectIdentifier}/tig`,
        tig,
        false,
        dispatch,
        "projects.createTig.flair|Mérföldkő hozzáadása",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.createTig.progress|Mérföldkő hozzáadása folyamatban...",
          fail: "projects.createTig.fail|Mérföldkő hozzáadása sikertelen!",
          success: "projects.createTig.success|Mérföldkő hozzáadása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveTig: async function ({ dispatch }, tigData) {
    try {
      await this.remoteRequest(
        "put",
        `${urls.tigUpdate}/${tigData.id}`,
        tigData,
        false,
        dispatch,
        "projects.saveTig.flair|Mérföldkő adatainak mentése...",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.saveTig.progress|Mérföldkő mentése folyamatban...",
          fail: "projects.saveTig.fail|Mérföldkő mentése sikertelen!",
          success: "projects.saveTig.success|Mérföldkő mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteTig: async function ({ dispatch }, tigId) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.tigUpdate}/${tigId}`,
        null,
        false,
        dispatch,
        "projects.deleteTig.flair|Mérföldkő törlése...",
        undefined,
        undefined,
        true,
        {
          progress:
            "projects.deleteTig.progress|Mérföldkő törlése folyamatban...",
          fail: "projects.deleteTig.fail|Mérföldkő törlése sikertelen!",
          success: "projects.deleteTig.success|Mérföldkő törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createOrEditOverride: async function ({ dispatch }, override) {
    try {
      await this.remoteRequest(
        "post",
        urls.override,
        override,
        false,
        dispatch,
        "projects.createOrEditOverride.flair|Költség felülírás mentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.createOrEditOverride.progress|Költség felülírás mentése folyamatban...",
          fail: "projects.createOrEditOverride.fail|Költség felülírás mentése sikertelen!",
          success:
            "projects.createOrEditOverride.success|Költség felülírás mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteOverride: async function ({ dispatch }, { projectId, userId }) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.override}/${projectId}/${userId}`,
        null,
        false,
        dispatch,
        "projects.deleteOverride.flair|Költség felülírás törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.deleteOverride.progress|Költség felülírás törlése folyamatban...",
          fail: "projects.deleteOverride.fail|Költség felülírás törlése sikertelen!",
          success:
            "projects.deleteOverride.success|Költség felülírás törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createOrEditExpense: async function ({ dispatch }, expense) {
    try {
      await this.remoteRequest(
        "post",
        urls.expense,
        expense,
        false,
        dispatch,
        "projects.createOrEditExpense.flair|Külön költség mentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.createOrEditExpense.progress|Költség mentése folyamatban...",
          fail: "projects.createOrEditExpense.fail|Költség mentése sikertelen!",
          success:
            "projects.createOrEditExpense.success|Költség mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteExpense: async function ({ dispatch }, { projectId, identifier }) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.expense}/${projectId}/${identifier}`,
        null,
        false,
        dispatch,
        "projects.deleteExpense.flair|Külön költség törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.deleteExpense.progress|Költség törlése folyamatban...",
          fail: "projects.deleteExpense.fail|Költség törlése sikertelen!",
          success: "projects.deleteExpense.success|Költség törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchInvoices: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.invoice,
        null,
        true,
        dispatch,
        "Számlák betöltése...",
        undefined,
        true
      );
      commit("saveInvoices", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createOrEditInvoice: async function ({ dispatch }, invoice) {
    try {
      await this.remoteRequest(
        "post",
        urls.projectInvoice,
        invoice,
        false,
        dispatch,
        "projects.createOrEditInvoice.flair|Számla mentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.createOrEditInvoice.progress|Számla mentése folyamatban...",
          fail: "projects.createOrEditInvoice.fail|Számla mentése sikertelen!",
          success:
            "projects.createOrEditInvoice.success|Számla mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  evaluateInvoice: async function ({ dispatch }, { id, request }) {
    try {
      await this.remoteRequest(
        "put",
        `${urls.projectInvoice}/${id}`,
        request,
        false,
        dispatch,
        "projects.evaluateInvoice.flair|Számla értékelése",
        undefined,
        undefined,
        true,
        {
          progress:
            "projects.evaluateInvoice.progress|Számla értékelése folyamatban...",
          fail: "projects.evaluateInvoice.fail|Számla értékelése sikertelen!",
          success:
            "projects.evaluateInvoice.success|Számla értékelése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  statusChange: async function (
    { dispatch },
    { identifier, status, date, projectType }
  ) {
    try {
      await this.remoteRequest(
        "post",
        `${urls.statusChange(identifier)}`,
        {
          status,
          date,
          projectType,
        },
        false,
        dispatch,
        "projects.statusChage.flair|Projekt státusz módosítása",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "projects.statusChage.progress|Státusz módosítása folyamatban...",
          fail: "projects.statusChage.fail|Státusz módosítása sikertelen!",
          success: "projects.statusChage.success|Státusz módosítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  archive: async function ({ dispatch }, identifier) {
    try {
      await this.remoteRequest(
        "post",
        `${urls.archive(identifier)}`,
        null,
        false,
        dispatch,
        "projects.archive.flair|Projekt archiválása",
        undefined,
        undefined,
        true,
        {
          progress: "projects.archive.progress|Archiválás folyamatban...",
          fail: "projects.archive.fail|Archiválás sikertelen!",
          success: "projects.archive.success|Archiválás sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  star: async function (
    { dispatch },
    { projectType, projectIdentifier, starred }
  ) {
    try {
      await this.remoteRequest(
        "PATCH",
        `${urls.star(projectType, projectIdentifier)}`,
        null,
        false,
        dispatch,
        "projects.star.flair|Projekt-kiemelés",
        undefined,
        undefined,
        true,
        {
          progress: starred
            ? "projects.star.progress.remove|Kielemés törlése folyamatban..."
            : "projects.star.progress.add|Kiemelés folyamatban...",
          fail: starred
            ? "projects.star.fail.remove|Kiemelés törlése sikertelen!"
            : "projects.star.progress.add|Kiemelés sikertelen!",
          success: starred
            ? "projects.star.success.remove|Kiemelés törlése sikeres!"
            : "projects.star.success.add|Kiemelés sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
        throw err;
      }
    }
  }.bind(storeCore),
  progressChange: async function ({ dispatch }, { identifier, body }) {
    try {
      await this.remoteRequest(
        "post",
        `${urls.progressChange(identifier)}`,
        body,
        false,
        dispatch,
        "Készültség módosítása",
        undefined,
        undefined,
        true
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  downloadProjectFile: async function ({ dispatch }, { fileId, fileName }) {
    try {
      await this.remoteRequest(
        "get",
        `${urls.projectFile(fileId)}`,
        {
          responseType: "blob",
        },
        true,
        dispatch,
        "Fájl letöltése..."
      ).then((response) => FileSaver.saveAs(response, fileName));
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteProjectFile: async function ({ dispatch }, fileId) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.document}/${fileId}`,
        null,
        false,
        dispatch,
        "Fájl törlése..."
      );
      return true;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchEpics: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.epics,
        null,
        false,
        dispatch,
        "Epikek betöltése...",
        undefined,
        true
      );
      commit("saveEpics", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  syncEpics: async function ({ commit, dispatch }) {
    try {
      await this.remoteRequest(
        "post",
        urls.syncEpics,
        null,
        false,
        dispatch,
        "Epikek szinkronizálása...",
        "30000",
        false
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchProjectMetadata: async function (
    { commit, dispatch },
    { projectIdentifier, projectType }
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.projectMetadata(projectIdentifier, projectType),
        null,
        false,
        dispatch,
        "Projekt meta adatainak betöltése...",
        0,
        true
      );
      commit("saveProjectMetadata", {
        identifier: projectIdentifier,
        data: data,
      });
      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  syncProjectMetadata: async function ({ commit, dispatch }, { prefix } = {}) {
    try {
      await this.remoteRequest(
        "post",
        urls.syncProjectMetadata(prefix),
        null,
        false,
        dispatch,
        "Projekt meta adatainak szinkronizálása...",
        "30000",
        true
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createTigTargeting: async function ({ dispatch }, { tigId, targetingData }) {
    try {
      await this.remoteRequest(
        "post",
        urls.createTigTargeting(tigId),
        targetingData,
        false,
        dispatch,
        "projects.createTigTargeting.flair|Mérföldkő jegy célzás adatainak mentése...",
        undefined,
        undefined,
        true,
        {
          progress:
            "projects.createTigTargeting.progress|Mérföldkő jegy célzás adatainak mentése folyamatban...",
          fail: "projects.createTigTargeting.fail|Mérföldkő jegy célzás adatainak mentése sikertelen!",
          success:
            "projects.createTigTargeting.success|Mérföldkő jegy célzás adatainak mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveProjectData: (state, { identifier, data }) => {
    if (data.technologies && !Array.isArray(data.technologies))
      data.technologies = data.technologies.split(",");
    if (data.laborTypes && !Array.isArray(data.laborTypes))
      data.laborTypes = data.laborTypes.split(",");
    if (data.costs && data.costs.length) {
      for (let c in data.costs) {
        data.costs[c].day = new Date(data.costs[c].date);
      }
    }
    if (data.invoices && data.invoices.length) {
      for (let c in data.invoices) {
        data.invoices[c].day = new Date(data.invoices[c].date);
      }
    }
    data.overrides = data.overrides || [];
    state.projectData[identifier] = data;
  },
  saveActiveTigs: (state, data) => {
    state.activeTigs = data;
  },
  saveTigData: (state, { identifier, data }) => {
    state.tigData[identifier] = data;
  },
  saveInvoices: (state, data) => {
    state.invoices = data;
  },
  saveProjectMetadata: (state, { identifier, data }) => {
    if (data) {
      data.fixVersions?.sort((a, b) => a.name.localeCompare(b.name));
      data.components?.sort((a, b) => a.name.localeCompare(b.name));
    }
    state.projectMetadata[identifier] = data;
  },
  saveEpics: (state, data) => {
    if (data) {
      data.sort((a, b) => a.name.localeCompare(b.name));
    }
    state.epics = data;
  },
};

const getters = {
  projectData: (state) => (identifier) => {
    return state.projectData[identifier];
  },
  activeTigs: (state) => {
    return state.activeTigs;
  },
  tigData: (state) => (identifier) => {
    return state.tigData[identifier];
  },
  invoices: (state) => {
    return state.invoices;
  },
  projectMetadata: (state) => (identifier) => {
    return state.projectMetadata[identifier];
  },
  epics: (state) => {
    return state.epics;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
